// src/pages/SidebarNew.js

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
    Box,
    Tooltip,
    styled,
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchIcon from '@mui/icons-material/Search'; // Import SearchIcon
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import { useTheme } from '@mui/material/styles';
import { drawerWidth, closedDrawerWidth } from '../constants'; // Import constants
import logo from '../assets/Project_Brief_Presentation__1_-removebg-preview.png'; // Adjust the path as needed

// Styled Drawer with Flexbox layout
const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
    width: open ? drawerWidth : closedDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiDrawer-paper': {
        width: open ? drawerWidth : closedDrawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: open
                ? theme.transitions.duration.enteringScreen
                : theme.transitions.duration.leavingScreen,
        }),
        boxSizing: 'border-box',
        backgroundColor: '#000000', // Desired background color
        color: theme.palette.text.primary,
        overflowX: 'hidden', // Prevent horizontal scroll
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Distribute space between top and bottom
    },
}));

function SidebarNew({ open, handleDrawerToggle }) {
    const theme = useTheme();
    const location = useLocation();

    const items = [
        { icon: <DashboardIcon />, text: 'Dashboard', path: '/' },
        { icon: <SearchIcon />, text: 'Search Engine', path: '/search' },
        { icon: <AccountCircleIcon />, text: 'Payment & Billing', path: '/profile' },
        { icon: <SettingsIcon />, text: 'Settings', path: '/settings' },
        { icon: <AccountCircleIcon />, text: 'Threat Actors', path: '/threat-actors' },
    ];

    return (
        <StyledDrawer variant="permanent" open={open}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                {/* Top Section: Toggle Button */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        padding: theme.spacing(0, 1),
                        ...theme.mixins.toolbar,
                        marginTop: '20px', // Adjust to position the toggle button
                    }}
                >
                    <IconButton
                        onClick={handleDrawerToggle}
                        sx={{
                            '&:hover': { backgroundColor: 'transparent' },
                            color: 'inherit', // Ensure icon color matches the theme
                        }}
                    >
                        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </Box>

                {/* Middle Section: Navigation Items */}
                <List sx={{ flexGrow: 1 }}>
                    {items.map(({ icon, text, path }) => (
                        <Tooltip key={text} title={text} placement="right" disableHoverListener={open}>
                            <ListItem
                                button
                                component={Link}
                                to={path}
                                selected={location.pathname === path}
                                sx={{
                                    '&:hover': { backgroundColor: 'transparent' },
                                    paddingLeft: open ? theme.spacing(2) : theme.spacing(1.5), // Adjust padding when closed
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        color: 'inherit',
                                        minWidth: 'unset',
                                        marginRight: open ? theme.spacing(2) : theme.spacing(1), // Adjust marginRight
                                        justifyContent: 'center',
                                    }}
                                >
                                    {icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={text}
                                    sx={{ opacity: open ? 1 : 0, whiteSpace: 'nowrap' }}
                                />
                            </ListItem>
                        </Tooltip>
                    ))}
                </List>

                {/* Bottom Section: Logo */}
                {open && (
                    <Box
                        sx={{
                            padding: theme.spacing(2),
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={logo}
                            alt="Logo"
                            style={{
                                maxWidth: '100%',
                                height: 'auto',
                                objectFit: 'contain',
                            }}
                        />
                    </Box>
                )}
            </Box>
        </StyledDrawer>
    );
}

export default SidebarNew;