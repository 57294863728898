import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Paper,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  styled,
  CircularProgress,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

const ChatContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#000',
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  boxShadow: 'none',
}));

const MessagesContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  marginBottom: theme.spacing(2),
  paddingRight: theme.spacing(1),
}));

const MessageBubble = styled(Paper)(({ theme, isUser }) => ({
  backgroundColor: isUser ? '#2f4f4f' : '#36454F',
  color: '#fff',
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(2),
  maxWidth: '75%',
  alignSelf: isUser ? 'flex-end' : 'flex-start',
  boxShadow: 'none',
  marginBottom: theme.spacing(1),
}));

const InputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  paddingTop: theme.spacing(1),
  borderTop: '1px solid #555',
  borderRadius: '12px',
  backgroundColor: '#1a1a1a',
}));

const Timestamp = styled(Typography)(({ theme }) => ({
  color: '#bbb',
  fontSize: '0.75rem',
  textAlign: 'right',
  marginTop: theme.spacing(0.5),
}));
const PredefinedQuestionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap', // Allow the buttons to wrap to a new row
  gap: theme.spacing(1),
  justifyContent: 'center', // Center the buttons in the container
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
  borderRadius: '12px',
}));

const GlassButton = styled(Button)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.2)',
  backdropFilter: 'blur(10px)', // Glass effect
  color: '#fff',
  textTransform: 'none',
  borderRadius: '20px',
  padding: theme.spacing(1, 2),
  minWidth: 'auto',
  whiteSpace: 'nowrap', // Prevent text wrapping inside the button
  fontSize: '0.85rem',
  transition: '0.3s ease',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.3)',
  },
}));

export default function ThreatFeed({ userData }) {
  const [messages, setMessages] = useState([
    {
      role: 'assistant',
      content: 'Welcome to Pardx! I’m your AI assistant, here to help you navigate the world of cyber threat intelligence. How can I assist you today? Feel free to ask about threat actors, recent cyber events, or insights tailored to your industry.',
      timestamp: new Date(),
    }
    
  ]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const auth = getAuth();

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const predefinedQuestions = [
    "Who are the top threat actors targeting my industry?",
    "What are the latest phishing campaigns in my region?",
    "Can you provide insights on vulnerabilities affecting my sector?",
    "Are there any leaked credentials linked to my organization?",
  ];

  const sendMessage = async (messageContent) => {
    const userMessage = {
      role: 'user',
      content: messageContent || input.trim(),
      timestamp: new Date(),
    };

    if (!userMessage.content) return;

    setMessages((prev) => [...prev, userMessage]);
    setInput('');
    setLoading(true);

    try {
      const user = auth.currentUser;
      let token = '';
      if (user) {
        token = await user.getIdToken(true);
      }

      const response = await axios.post(
        'https://ln2jj4k2-3005.euw.devtunnels.ms/api/chat',
        {
          messages: [...messages, userMessage],
          userData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const aiMessage = {
        role: 'assistant',
        content: response.data.content,
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, aiMessage]);
    } catch (error) {
      console.error('Error communicating with AI:', error);
      const errorMessage = {
        role: 'assistant',
        content: 'Sorry, there was an error processing your request.',
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <ChatContainer>
      <MessagesContainer>
        <List disablePadding>
          {messages.map((message, index) => (
            <ListItem
              key={index}
              disableGutters
              sx={{ display: 'flex', flexDirection: 'column', alignItems: message.role === 'user' ? 'flex-end' : 'flex-start' }}
            >
              <MessageBubble elevation={0} isUser={message.role === 'user'}>
                <ListItemText primary={message.content} />
              </MessageBubble>
              <Timestamp>
                {message.timestamp.toLocaleString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </Timestamp>
            </ListItem>
          ))}
          <div ref={messagesEndRef} />
        </List>
      </MessagesContainer>
      {loading && (
        <Box display="flex" alignItems="center" mb={1}>
          <CircularProgress size={20} />
          <Typography variant="body1" color="white" sx={{ ml: 1 }}>
            AI is typing...
          </Typography>
        </Box>
      )}
      <PredefinedQuestionsContainer>
        {predefinedQuestions.map((question, index) => (
          <GlassButton key={index} onClick={() => sendMessage(question)}>
            {question}
          </GlassButton>
        ))}
      </PredefinedQuestionsContainer>
      <InputContainer>
        <TextField
          variant="outlined"
          placeholder="Type your message..."
          fullWidth
          multiline
          maxRows={4}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress}
          InputProps={{
            style: { backgroundColor: '#1a1a1a', color: '#fff' },
            sx: {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#555',
                borderRadius: '12px',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#777',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#4a90e2',
              },
              color: '#fff',
            },
          }}
        />
        <IconButton
          color="primary"
          onClick={() => sendMessage()}
          sx={{
            backgroundColor: '#000',
            width: '40px',
            height: '40px',
            '&:hover': {
              backgroundColor: '#333',
            },
          }}
        >
          <SendIcon sx={{ color: '#fff' }} />
        </IconButton>
      </InputContainer>
    </ChatContainer>
  );
}
