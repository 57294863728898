// Signup.js
import React, { useRef, useState } from 'react';
import './Signup.css';
import { Button } from '@mui/material';

const Signup = ({ isAuthenticated, handleLogout }) => {
    const emailRef = useRef();
    const passwordRef = useRef();
    const fullNameRef = useRef();
    const phoneNumberRef = useRef();
    const companyDomainRef = useRef();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    // Set the default plan
    const plan = 'essential';

    const handleSignup = async (event) => {
        event.preventDefault();
        setError(null);

        const email = emailRef.current.value.trim();
        const password = passwordRef.current.value;
        const fullName = fullNameRef.current.value.trim();
        const phoneNumber = phoneNumberRef.current.value.trim();
        const companyDomain = companyDomainRef.current.value.trim();

        // Basic Validation
        if (!email || !password || !fullName || !phoneNumber || !companyDomain) {
            setError('All fields are required.');
            return;
        }

        // Email format validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        // Password strength validation (minimum 6 characters as example)
        if (password.length < 6) {
            setError('Password must be at least 6 characters long.');
            return;
        }

        // Phone number validation (simple example, adjust as needed)
        const phoneRegex = /^\+?[1-9]\d{1,14}$/; // E.164 format
        if (!phoneRegex.test(phoneNumber)) {
            setError('Please enter a valid phone number.');
            return;
        }

        setLoading(true);

        try {
            const response = await fetch('https://ln2jj4k2-3005.euw.devtunnels.ms/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password, fullName, phoneNumber, companyDomain, plan })
            });

            const data = await response.json();
            console.log('Signup response data:', data);

            if (response.ok) {
                window.location.href = data.url; // Redirect to Stripe Checkout
            } else {
                setError(data.error || 'Signup failed. Please try again.');
            }
        } catch (error) {
            console.error('Error signing up:', error);
            setError('Failed to sign up. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    if (isAuthenticated) {
        return (
            <div className="signup-container">
                <h2>You are already logged in.</h2>
                <p>If you want to create a new account, please log out first.</p>
                <Button variant="contained" color="primary" onClick={handleLogout}>
                    Log Out
                </Button>
            </div>
        );
    }

    return (
        <>
            <div className="background-image"></div>
            <div className="container">
                <h1>Sign Up</h1>
                <form onSubmit={handleSignup} className="signup-form">
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input 
                            id="email"
                            type="email"
                            placeholder="Email"
                            ref={emailRef}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <div className="password-input-container">
                            <input 
                                id="password"
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                ref={passwordRef}
                                required
                            />
                            <button 
                                type="button" 
                                onClick={togglePasswordVisibility} 
                                className="toggle-password"
                            >
                                {showPassword ? "Hide" : "Show"}
                            </button>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="fullName">Full Name</label>
                        <input 
                            id="fullName"
                            type="text"
                            placeholder="Full Name"
                            ref={fullNameRef}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input 
                            id="phoneNumber"
                            type="tel"
                            placeholder="Phone Number"
                            ref={phoneNumberRef}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="companyDomain">Company Domain</label>
                        <input 
                            id="companyDomain"
                            type="text"
                            placeholder="Company Domain"
                            ref={companyDomainRef}
                            required
                        />
                    </div>

                    {error && <p className="error-message">{error}</p>}

                    <button type="submit" disabled={loading}>
                        {loading ? 'Signing Up...' : 'Sign Up'}
                    </button>
                </form>
            </div>
        </>
    );
};

export default Signup;
