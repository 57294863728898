import React, { useState, useEffect, useCallback } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import { CircularProgress, CssBaseline, Box } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import ProfilePage from './pages/ProfilePage';
import Login from './pages/login'; 
import Signup from './pages/Signup';
import SidebarNew from './pages/SidebarNew';
import SearchPage from './pages/SearchPage';  
import SettingsPage from './pages/Settings';  
import './App.css';  // Global CSS file, keep minimal

const firebaseConfig = {
    apiKey: "AIzaSyDrCHk6bFUXYuE30-5kDvlOrbKWZbIebts",
    authDomain: "pardx-275de.firebaseapp.com",
    projectId: "pardx-275de",
    storageBucket: "pardx-275de.appspot.com",
    messagingSenderId: "594635262555",
    appId: "1:594635262555:web:720905400193861abbcd7e",
    measurementId: "G-SD4SLJ2B76"
};
// Initialize Firebase
initializeApp(firebaseConfig);
const auth = getAuth();

const theme = createTheme({
    palette: {
        mode: 'dark',  // Keep the default dark mode for the app
        primary: {
            main: '#0071E3', 
        },
        background: {
            default: '#121212', // Default dark background for the app
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backdropFilter: 'blur(10px)',
                    backgroundColor: 'rgba(255, 255, 255, 0.72)', 
                },
            },
        },
    },
});

function App() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(true);

    const location = useLocation(); // Get the current route

    // Define paths where the sidebar should not be displayed
    const noSidebarRoutes = ['/login', '/signup'];

    const fetchData = useCallback(async () => {
        const user = auth.currentUser;
        if (user) {
            try {
                const token = await user.getIdToken(true);
                const response = await axios.get(`https://ln2jj4k2-3005.euw.devtunnels.ms/api/dashboard`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        }
    }, []);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setIsAuthenticated(!!user);
            if (user) {
                fetchData();
            } else {
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, [fetchData]);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    if (loading) {
        return (
            <div className="loading-container">
                <CircularProgress />
            </div>
        );
    }

    // Determine whether to show the sidebar based on the current route and authentication status
    const showSidebar = isAuthenticated && !noSidebarRoutes.includes(location.pathname);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ display: 'flex', height: '100vh' }}>
                {showSidebar && (
                    <SidebarNew open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
                )}
                <Box 
                    component="main" 
                    sx={{ 
                        flexGrow: 1, 
                        overflow: 'auto',
                        minHeight: '100vh',
                        backgroundColor: '#ffffff', // Default to white for SearchPage
                    }}
                >
                    <Routes>
                        {/* Keep existing routes */}
                        <Route path="/" element={isAuthenticated ? <Dashboard data={data} /> : <Login setIsAuthenticated={setIsAuthenticated} />} />
                        <Route path="/profile" element={isAuthenticated ? <ProfilePage /> : <Login setIsAuthenticated={setIsAuthenticated} />} />
                        
                        {/* Modify the SearchPage route to ensure full-screen responsiveness */}
                        <Route 
                            path="/search" 
                            element={
                                <Box 
                                    sx={{ 
                                        height: '100vh',  // Ensure it takes up full height
                                        width: '100%',    // Full width of the viewport
                                        backgroundColor: '#ffffff', // White background for the search page
                                    }}
                                >
                                    {isAuthenticated ? <SearchPage /> : <Login setIsAuthenticated={setIsAuthenticated} />}
                                </Box>
                            } 
                        />
                        
                        <Route path="/settings" element={isAuthenticated ? <SettingsPage /> : <Login setIsAuthenticated={setIsAuthenticated} />} />
                        <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
                        <Route path="/signup" element={<Signup />} />
                    </Routes>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

const firebaseApp = initializeApp(firebaseConfig);
export { firebaseApp };

export default function AppWrapper() {
    return (
        <BrowserRouter>
            <App />
        </BrowserRouter>
    );
}
