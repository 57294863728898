// src/pages/Dashboard.js

import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  useTheme,
  Tooltip,
  Drawer,
  Fab,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  styled,
  Button,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ChatIcon from '@mui/icons-material/Chat';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import BusinessIcon from '@mui/icons-material/Business';
import SecurityIcon from '@mui/icons-material/Security';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// Components
import RecentCVE from './Components/RecentCVE';
import HeatMap from './Components/HeatMap';
import FindingsTable from './Components/FindingsTable';
import OverviewCard from './Components/OverviewCard';
import ThreatFeed from './Components/ThreatFeed';
// import BrandProtection from './Components/BP'; // Not currently used in original code.

// ====== Styles ====== //
const MainContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  backgroundColor: '#000', // Or use theme.palette.background.default, etc.
}));

// ====== Utility function to standardize detection findings ====== //
function mapFindingToStandardFormat(finding) {
  let date, source, type, detail, id;

  // Example detection type A:
  if (finding.dateOfDetection && finding.port) {
    id = finding.id;
    date = finding.dateOfDetection;
    source = 'Network Scan';
    type = `Open Port - ${finding.port}`;
    detail = finding.detail;

  // Example detection type B:
  } else if (finding.date && finding.partitionKey) {
    id = finding.id;
    date = finding.date;
    source = finding.channel_name || 'Unknown Source';
    type = 'Compromised credentials';
    detail = finding.content || 'No details available';

  // Fallback/unknown type:
  } else {
    id = finding.id || 'Unknown';
    date = finding.detectionDate || 'Unknown';
    source = 'Unknown Source';
    type = 'Unknown Type';
    detail = 'No details available';
  }

  return { id, date, source, type, detail };
}

// ====== Custom Hook: AI Chat Logic ====== //
function useAiChat(initialMessages = [{ role: 'assistant', content: 'Hello! How can I help you today?' }]) {
  const [messages, setMessages] = useState(initialMessages);
  const [inputValue, setInputValue] = useState('');
  const [loadingAI, setLoadingAI] = useState(false);

  function handleInputChange(value) {
    setInputValue(value);
  }

  function handleSendMessage() {
    if (!inputValue.trim()) return;

    // User message
    const userMessage = { role: 'user', content: inputValue.trim() };
    setMessages((prev) => [...prev, userMessage]);

    // Simulated AI response
    setLoadingAI(true);
    setTimeout(() => {
      const aiResponse = { role: 'assistant', content: 'This is an AI response.' };
      setMessages((prev) => [...prev, aiResponse]);
      setLoadingAI(false);
    }, 1000);

    // Reset input
    setInputValue('');
  }

  return {
    messages,
    inputValue,
    loadingAI,
    handleInputChange,
    handleSendMessage,
  };
}

// ====== Separate component for the "header" or "overview" section ====== //
function DashboardHeader({ data }) {
  const {
    detections = [],
    searches = [],
    similarDomains = [],
  } = data;

  const detectionCount = detections.length;
  const reportCount = searches.length || 0;
  const similarDomainsCount = similarDomains.length;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3}>
        <OverviewCard
          title="Detected Breaches Count"
          value={detectionCount.toLocaleString()}
          icon={<BusinessIcon />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <OverviewCard
          title="Number of Searches"
          value={reportCount.toLocaleString()}
          icon={<SecurityIcon />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <OverviewCard
          title="Monitoring Status"
          value="Active"
          icon={<AccessTimeIcon />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <OverviewCard
          title="Potential Threats Monitored"
          value={similarDomainsCount.toLocaleString()}
          icon={<AccessTimeIcon />}
        />
      </Grid>
    </Grid>
  );
}

// ====== Separate component for "sections" (CVE, Table, HeatMap, etc.) ====== //
function DashboardSections({ data }) {
  const { detections = [] } = data;

  // Standardize detection findings
  const standardizedFindings = detections.map(mapFindingToStandardFormat);

  return (
    <>
      {/* Recent Events & Detections */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box>
            <SectionTitle text="Recent Events & Detections" tooltip="Latest events and detections across the platform." />
            <RecentCVE detections={detections} />
          </Box>
        </Grid>
      </Grid>

      {/* Telegram Monitoring */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box>
            <SectionTitle text="Telegram Monitoring" tooltip="Monitoring Telegram for VIP protection and other security events." />
            <FindingsTable findings={standardizedFindings} />
          </Box>
        </Grid>
      </Grid>

      {/* Incident HeatMap */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box>
            <HeatMap />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

// ====== Small helper to standardize section titles with a tooltip ====== //
function SectionTitle({ text, tooltip }) {
  return (
    <Box display="flex" alignItems="center" mb={1}>
      <Typography variant="h6" gutterBottom color="white">
        {text}
      </Typography>
      <Tooltip title={tooltip} arrow>
        <InfoIcon sx={{ color: 'grey', marginLeft: 1, fontSize: '1.2rem' }} />
      </Tooltip>
    </Box>
  );
}

// ====== Main Dashboard Component ====== //
export default function Dashboard({ data, loading }) {
  const theme = useTheme();

  // AI Chat states (custom hook)
  const {
    messages,
    inputValue,
    loadingAI,
    handleInputChange,
    handleSendMessage,
  } = useAiChat();

  // Drawer + Dialog states
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Toggles
  const toggleDrawer = (open) => () => setIsDrawerOpen(open);
  const openDialog = () => {
    setIsDrawerOpen(false); // close the drawer if open
    setIsDialogOpen(true);
  };
  const closeDialog = () => setIsDialogOpen(false);

  // Loading State
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  // No data available
  if (!data) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography variant="h5" color="white">
          No data available at the moment. Please check back later.
        </Typography>
      </Box>
    );
  }

  return (
    <MainContent>
      {/* Overview / Header Cards */}
      <DashboardHeader data={data} />

      {/* Main Dashboard Sections (CVE, Tables, HeatMap, etc.) */}
      <DashboardSections data={data} />

      {/* Floating Action Button for the AI Assistant */}
      <Fab
        color="primary"
        aria-label="chat"
        onClick={toggleDrawer(true)}
        sx={{
          position: 'fixed',
          bottom: theme.spacing(4),
          right: theme.spacing(4),
          backgroundColor: '#000',
          '&:hover': {
            backgroundColor: '#333',
          },
        }}
      >
        <ChatIcon sx={{ color: '#fff' }} />
      </Fab>

      {/* Drawer with AI ThreatFeed */}
      <Drawer
        anchor="right"
        open={isDrawerOpen && !isDialogOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: '600px' },
            backgroundColor: '#1a1a1a',
            color: '#fff',
            borderTopLeftRadius: '20px',
            borderBottomLeftRadius: '20px',
            boxShadow: 'none',
          },
        }}
      >
        <Box role="presentation" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          {/* Drawer Header */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            sx={{
              borderBottom: '1px solid #333',
              borderTopLeftRadius: '20px',
            }}
          >
            <Typography variant="h6">AI Assistant</Typography>
            <Box display="flex" gap={1} alignItems="center">
              <Tooltip title="Expand to larger view" arrow>
                <IconButton onClick={openDialog} sx={{ color: '#fff' }}>
                  <FullscreenIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Close" arrow>
                <IconButton onClick={toggleDrawer(false)} sx={{ color: 'grey' }}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          {/* Drawer Content */}
          <Box flexGrow={1} p={2} overflow="auto">
            <ThreatFeed
              userData={data}
              messages={messages}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              onSendMessage={handleSendMessage}
              loading={loadingAI}
            />
          </Box>
        </Box>
      </Drawer>

      {/* Dialog for expanded AI ThreatFeed */}
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          sx: {
            backgroundColor: '#1a1a1a',
            color: '#fff',
            borderRadius: '20px',
            overflow: 'hidden',
          },
        }}
      >
        <DialogTitle
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant="h6">AI Assistant (Expanded View)</Typography>
          <IconButton
            onClick={closeDialog}
            size="small"
            disableRipple
            disableFocusRipple
            sx={{
              p: '4px',
              color: 'grey',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#fff',
              },
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '70vh' }}>
          <ThreatFeed
            userData={data}
            messages={messages}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            onSendMessage={handleSendMessage}
            loading={loadingAI}
          />
        </DialogContent>
      </Dialog>
    </MainContent>
  );
}
