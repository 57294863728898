import React, { useState } from 'react';
import { 
  Box, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper, Typography, Drawer, 
  Button, Chip, CircularProgress 
} from '@mui/material';
import { green, red, yellow } from '@mui/material/colors';
import { parseISO, differenceInMonths, format } from 'date-fns';
import { getAuth } from 'firebase/auth';

const RecentCVE = ({ detections }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [insights, setInsights] = useState(null);
  const [loadingInsights, setLoadingInsights] = useState(false);
  const [insightsError, setInsightsError] = useState(null);

  const auth = getAuth();

  // Utility function to check if a date is within the last 3 months
  const isWithinLastThreeMonths = (dateStr) => {
    try {
      const eventDate = parseISO(dateStr);
      const currentDate = new Date();
      const monthsDifference = differenceInMonths(currentDate, eventDate);
      return monthsDifference < 3;
    } catch (error) {
      console.error(`Invalid date format: ${dateStr}`, error);
      return false;
    }
  };

  // Function to map severity to numerical level
  const getSeverityLevel = (severity) => {
    switch (severity) {
      case 'Low':
        return 1;
      case 'Medium':
        return 2;
      case 'High':
        return 3;
      case 'Critical':
        return 4;
      default:
        return 0;
    }
  };

  // Function to adjust severity based on presence of email or content field and date
  const adjustSeverity = (group) => {
    // Determine if any event in the group has email or content
    const hasCompromisedCredential = group.events.some(event => event.email || event.content);
    if (hasCompromisedCredential) {
      const adjustedSeverity = isWithinLastThreeMonths(group.date) ? 'High' : 'Medium';
      return adjustedSeverity;
    }
    return group.severity || 'Low'; // Default to 'Low' if severity is undefined
  };

  // Function to group detections by channel_username and date
  const groupDetections = (detections) => {
    const groups = {};

    detections.forEach((event) => {
      if (!event.channel_username || !event.date) {
        console.warn(`Event missing channel_username or date:`, event);
        return;
      }

      // For demo events, use the event ID as the group ID
      let key;
      if (event.id && event.id.startsWith('Dummy-')) {
        key = event.id;
      } else {
        key = `${event.channel_username}_${event.date}`;
      }

      if (!groups[key]) {
        groups[key] = {
          id: key, // Unique group ID
          channel_username: event.channel_username,
          date: event.date,
          events: [event],
          severity: adjustSeverity({ ...event, events: [event] }),
        };
      } else {
        groups[key].events.push(event);
        const currentSeverityLevel = getSeverityLevel(groups[key].severity);
        const eventSeverity = adjustSeverity(groups[key]);
        const eventSeverityLevel = getSeverityLevel(eventSeverity);
        if (eventSeverityLevel > currentSeverityLevel) {
          groups[key].severity = eventSeverity;
        }
      }
    });

    return Object.values(groups);
  };

  // Dummy data to show when there are no detections
  const dummyData = [
    {
      id: 'Dummy-001',
      severity: 'Medium',
      channel_username: 'System Placeholder',
      date: '2024-07-15T00:00:00Z',
      email: 'user1@example.com',
      content: 'Simulated compromised credential event within last 3 months.',
      recommendedActions: 'Reset passwords and monitor account activity.',
    },
  ];

  // Use the dummyData if detections are empty
  const displayData = detections && detections.length > 0 ? groupDetections(detections) : groupDetections(dummyData);

  // Function to format date to user-friendly format
  const formatDate = (dateStr) => {
    try {
      const date = parseISO(dateStr);
      return format(date, 'MMMM d, yyyy');
    } catch (error) {
      console.error(`Error formatting date: ${dateStr}`, error);
      return dateStr;
    }
  };

  // Function to generate a user-friendly event name
  const generateEventName = (group) => {
    const channel = group.channel_username;
    const dateFormatted = formatDate(group.date);
    return `${channel} - ${dateFormatted}`;
  };

  // Function to fetch AI insights from the backend or use demo insights
  const fetchInsights = async (group) => {
    setLoadingInsights(true);
    setInsightsError(null);
    setInsights(null);

    // Aggregate content and emails from all events in the group
    const aggregatedContent = group.events.map(event => {
      if (typeof event.content === 'string') {
        return event.content;
      } else if (event.content) {
        return JSON.stringify(event.content);
      } else {
        return 'No content provided';
      }
    }).join(' ');

    const aggregatedEmails = group.events.map(event => {
      if (typeof event.email === 'string') {
        return event.email;
      } else if (event.email) {
        return JSON.stringify(event.email);
      } else {
        return null;
      }
    }).filter(email => email).join(', ') || 'No email provided';

    // Check if the group is a demo event
    if (group.id.startsWith('Dummy-')) {
      // Use demo insights without sending a request to the backend
      const demoInsights = {
        headline: 'Compromised Credential Event Detected',
        date: group.date,
        source: 'System Placeholder',
        type: 'Credential Compromise',
        detail: 'A compromised credential event was detected, specifically involving the email address \'user1@example.com\'. The event was simulated within the last 3 months.',
        threatLevel: 'Medium',
        executiveSummary: 'Our system has detected a potential security threat involving a compromised credential linked to the email address \'user1@example.com\'. This could potentially lead to unauthorized access or data breaches within your organization.',
        actionableSteps: [
          'Initiate an immediate password reset for the compromised email account.',
          'Notify the user and IT team about the potential breach.',
          'Review and update security protocols, including multi-factor authentication.',
          'Monitor the account for any unusual activities.',
        ],
        additionalDetails: {
          description: 'Simulated compromised credential event within last 3 months.',
        },
        email: aggregatedEmails,
        content: aggregatedContent,
      };
      setInsights(demoInsights);
      setLoadingInsights(false);
      return;
    }

    // For real events, send a request to the backend
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }

      const token = await user.getIdToken(true);

      const threatData = {
        id: group.id,
        description: aggregatedContent,
        channel_username: group.channel_username,
        date: group.date,
        severity: group.severity,
        email: aggregatedEmails,
        content: aggregatedContent,
      };

      const response = await fetch('https://ln2jj4k2-3005.euw.devtunnels.ms/api/generate-insights', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          threatData,
          threatId: group.id,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Failed to fetch insights.');
      }

      const data = await response.json();
      const insightsData = data.insights || {};

      // Ensure actionableSteps is an array
      insightsData.actionableSteps = Array.isArray(insightsData.actionableSteps)
        ? insightsData.actionableSteps
        : insightsData.actionableSteps
        ? [insightsData.actionableSteps]
        : [];

      setInsights(insightsData);
    } catch (error) {
      console.error('Error fetching insights:', error);
      setInsightsError(error.message || 'An unexpected error occurred.');
      setInsights({});
    } finally {
      setLoadingInsights(false);
    }
  };

  // Handle row click to open the side panel and fetch insights
  const handleRowClick = (group) => {
    setSelectedEvent(group);
    fetchInsights(group);
  };

  // Handle closing the side panel
  const handleClose = () => {
    setSelectedEvent(null);
    setInsights(null);
    setInsightsError(null);
    setLoadingInsights(false);
  };

  // Severity color logic
  const getSeverityColor = (severity) => {
    switch (severity) {
      case 'Critical':
        return red[600];
      case 'High':
        return red[300];
      case 'Medium':
        return yellow[700];
      case 'Low':
        return green[500];
      default:
        return 'grey';
    }
  };

  // Helper function to render fields safely
  const renderField = (label, value) => (
    <Typography variant="body2" sx={{ color: 'white' }}>
      <strong>{label}:</strong>{' '}
      {value !== null && value !== undefined
        ? typeof value === 'string' || typeof value === 'number'
          ? value
          : JSON.stringify(value)
        : 'N/A'}
    </Typography>
  );

  return (
    <Box sx={{ marginTop: 3 }}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: '#1e1e1e', maxHeight: '400px', overflowY: 'auto' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Event Name</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Severity</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Channel Username</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Date</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Content</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayData.map((group) => (
              <TableRow
                key={group.id}
                onClick={() => handleRowClick(group)}
                sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#333333' } }}
              >
                <TableCell sx={{ color: 'white' }}>{generateEventName(group)}</TableCell>
                <TableCell>
                  <Chip
                    label={group.severity}
                    sx={{
                      backgroundColor: getSeverityColor(group.severity),
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  />
                </TableCell>
                <TableCell sx={{ color: 'white' }}>{group.channel_username}</TableCell>
                <TableCell sx={{ color: 'white' }}>{formatDate(group.date)}</TableCell>
                <TableCell sx={{ color: 'white' }}>
                  {group.events.map((event, idx) => (
                    <Typography key={idx} variant="body2" sx={{ color: 'white' }}>
                      {typeof event.content === 'string'
                        ? event.content.length > 50
                          ? `${event.content.substring(0, 50)}...`
                          : event.content
                        : JSON.stringify(event.content)}
                    </Typography>
                  ))}
                </TableCell>
                <TableCell sx={{ color: 'white' }}>{group.events.length}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Drawer anchor="right" open={Boolean(selectedEvent)} onClose={handleClose}>
        <Box
          sx={{ width: 350, padding: 3, backgroundColor: '#2c2c2c', height: '100%', overflowY: 'auto' }}
        >
          {selectedEvent && (
            <>
              {/* Event Details */}
              <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold', marginBottom: 2 }}>
                Event Details
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'white' }}>
                <strong>Event Name:</strong> {generateEventName(selectedEvent)}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'white' }}>
                <strong>Severity:</strong>
                <Chip
                  label={selectedEvent.severity}
                  sx={{ backgroundColor: getSeverityColor(selectedEvent.severity), color: 'white', marginLeft: 1 }}
                />
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'white' }}>
                <strong>Channel Username:</strong> {selectedEvent.channel_username}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'white' }}>
                <strong>Date:</strong> {formatDate(selectedEvent.date)}
              </Typography>
              <Typography variant="body2" sx={{ color: 'white', marginTop: 2 }}>
                <strong>Detections:</strong>
              </Typography>
              {selectedEvent.events.map((e, index) => (
                <Box
                  key={index}
                  sx={{
                    marginTop: 1,
                    padding: 1,
                    backgroundColor: '#1e1e1e',
                    borderRadius: 1,
                  }}
                >
                  {renderField('Event ID', e.id.split('_')[0])}
                  {e.email && renderField('Type', 'Compromised Credential')}
                  {e.content && renderField('Content', e.content)}
                  {renderField('Severity', adjustSeverity(selectedEvent))}
                  {e.email && (
                    <Typography variant="body2" sx={{ color: 'white', marginTop: 0.5 }}>
                      <strong>Note:</strong> Severity adjusted based on the event date.
                    </Typography>
                  )}
                  {renderField('Description', e.description || 'No description available.')}
                  {renderField('Recommended Actions', e.recommendedActions || 'None provided.')}
                </Box>
              ))}

              {/* AI Insights */}
              <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold', marginTop: 3, marginBottom: 2 }}>
                AI-Generated Insights
              </Typography>

              {loadingInsights && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress color="inherit" size={24} />
                  <Typography variant="body2" sx={{ color: 'white', marginLeft: 2 }}>
                    Generating insights...
                  </Typography>
                </Box>
              )}

              {insightsError && (
                <Typography variant="body2" sx={{ color: 'red' }}>
                  Error: {insightsError}
                </Typography>
              )}

              {insights && !loadingInsights && (
                <Box sx={{ color: 'white' }}>
                  {renderField('Headline', insights.headline)}
                  {renderField('Date', insights.date ? formatDate(insights.date) : 'N/A')}
                  {renderField('Source', insights.source)}
                  {renderField('Type', insights.type)}
                  {renderField('Detail', insights.detail)}
                  {renderField('Threat Level', insights.threatLevel)}
                  {renderField('Executive Summary', insights.executiveSummary)}
                  <Typography variant="subtitle1">
                    <strong>Actionable Steps:</strong>
                  </Typography>
                  <ul>
                    {Array.isArray(insights.actionableSteps) && insights.actionableSteps.length > 0 ? (
                      insights.actionableSteps.map((step, index) => (
                        <li key={index}>{step}</li>
                      ))
                    ) : (
                      <li>N/A</li>
                    )}
                  </ul>

                  {/* Handle additionalDetails properly */}
                  {insights.additionalDetails && typeof insights.additionalDetails === 'object' ? (
                    <>
                      <Typography variant="subtitle1">
                        <strong>Additional Details:</strong>
                      </Typography>
                      {Object.entries(insights.additionalDetails).map(([key, value]) => (
                        renderField(key.charAt(0).toUpperCase() + key.slice(1), value)
                      ))}
                    </>
                  ) : (
                    renderField('Additional Details', insights.additionalDetails)
                  )}

                  {/* Display email and content if they are strings */}
                  {insights.email && renderField('Email', insights.email)}
                  {insights.content && renderField('Content', insights.content)}
                </Box>
              )}

              <Button variant="contained" color="primary" sx={{ marginTop: 3 }} onClick={handleClose} fullWidth>
                Close
              </Button>
            </>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default RecentCVE;
