// ProfilePage.js
import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

const ProfilePage = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const auth = getAuth();
    const db = getFirestore();

    useEffect(() => {
        const fetchUserDataAndRedirect = async() => {
            const currentUser = auth.currentUser;
            if (currentUser) {
                try {
                    const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        const customerId = userData.stripeCustomerId;
                        if (customerId) {
                            const response = await axios.post('https://ln2jj4k2-3005.euw.devtunnels.ms/create-customer-portal-session', {
                                userId: currentUser.uid
                            });
                            window.location.href = response.data.url;
                        } else {
                            setError('Stripe Customer ID not found');
                        }
                    } else {
                        setError('User data not found in Firestore');
                    }
                } catch (error) {
                    console.error('Error fetching user data or creating customer portal session:', error);
                    setError('Error fetching user data or creating customer portal session');
                }
            } else {
                setError('User not authenticated');
            }
            setLoading(false);
        };

        fetchUserDataAndRedirect();
    }, [auth, db]);

    if (loading) return <div className = "loading-container" > < CircularProgress / > < /div>;
    if (error) return <div > { error } < /div>;

    return null;
};

export default ProfilePage;